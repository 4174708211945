.project-sidenav {
    .mat-mdc-list-item {
        height: 40px!important;
        margin-bottom: 4px;

        .mdc-list-item__primary-text {
            color: #D1D5DB!important;
            font-weight: 500;
        }
        svg path {
            stroke: #D1D5DB;
        }
    }

    .mdc-list-item--activated {
        .mdc-list-item__primary-text {
            color: white!important;
        }
        svg path {
            stroke: white;
        }
    }
}


.sidenav-backdrop.mat-sidenav-container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1009;
    background: #00000080;
    .mat-drawer-backdrop {
        background: transparent;
    }
}