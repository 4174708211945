@use "assets/scss/mat-theme";
@use "assets/scss/scroll";
@use "assets/scss/table";
@use "assets/scss/tooltip";
@use "assets/scss/form-field";
@use "assets/scss/wallet-style.scss";
@use "assets/scss/sidenav";
@use "assets/scss/tabs";

@tailwind base;
@tailwind components;
@tailwind utilities;
