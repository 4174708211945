.balance-tabs{
  .mdc-tab mat-mdc-tab{
    --mdc-secondary-navigation-tab-container-height: auto;
    height: auto;
  }
  .mdc-tab-indicator--active{
    .mdc-tab__text-label{
      @apply [&>div]:bg-white [&>div]:text-[#2D67F7];
    }
  }
  .mat-mdc-tab-header{
    --mdc-secondary-navigation-tab-container-height: auto;
    @apply self-start;
  }
  .mat-mdc-tab-list,
  .mat-mdc-tab-label-container{
    @apply grow-0;
  }
  .mdc-tab-indicator{
    @apply hidden;
  }
  .mat-mdc-tab-labels{
    .mat-ripple{
      @apply hidden;
    }
    .mdc-tab__ripple{
      &:before{
        @apply hidden;
      }
    }
    @apply gap-[8px];
    .mdc-tab{
      @apply p-0 rounded overflow-hidden;
    }
  }
}

.report-tabs.mat-mdc-tab-group {
  .mat-mdc-tab-body-content {
    overflow-y: hidden;
  }
  .mat-mdc-tab-label-container {
    .mdc-tab {
      border-radius: 4px;
      font-size: 10px;
      font-weight: 700;
      text-transform: uppercase;
      color: #022D35;
      padding: 8px;
      min-width: unset;
      height: auto;
      flex-grow: unset !important;
      font-family: 'Inter';
      .mdc-tab__text-label {
        color: #022D35;
        transition-delay: 0ms;
      }
      &.mdc-tab--active {
        background: #022D35;
        color: #FFFFFF;
        .mdc-tab__text-label {
          color: #FFFFFF;
        }
      }
      .mdc-tab__content {
        min-width: unset;
        height: auto;
      }
      .mdc-tab-indicator, .mdc-tab__ripple, .mat-ripple {
        display: none;
      }
    }
  }
}
