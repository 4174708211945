.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label .mat-mdc-form-field-required-marker {
  color: var(--mdc-theme-error, #c93535);
}

.mdc-line-ripple {
  display: none;
}


// Fix border
.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-right-style: hidden;
}
.mat-mdc-input-element {
  box-shadow: none;
}
// ----------------------


.light {
  .mdc-text-field {
    color: #1C1E22;
    border-bottom-right-radius: var(--mdc-shape-small, 4px);
    border-bottom-left-radius: var(--mdc-shape-small, 4px);
  }

  .mat-mdc-input-element::placeholder {
    color: rgba(#1C1E22, 0.3);
  }

  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label,
  .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: #1C1E22;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label,
  .mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label--float-above {
    color: #1C1E22;
  }
}

.dark {
  .mdc-text-field {
    color: #FFFFFF;
    border-bottom-right-radius: var(--mdc-shape-small, 4px);
    border-bottom-left-radius: var(--mdc-shape-small, 4px);
  }

  .mat-mdc-input-element::placeholder {
    color: rgba(#FFFFFF, 0.3);
  }

  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label,
  .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: #FFFFFF;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label,
  .mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label--float-above {
    color: #FFFFFF;
  }
}

.medium-size {
  --mat-form-field-container-vertical-padding: 8px;
  --mat-form-field-container-height: 40px;
}

.panel-without-pseudo {
  .mat-pseudo-checkbox {
    display: none;
  }
}