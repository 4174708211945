@use "@angular/material" as mat;

@include mat.elevation-classes();
@include mat.app-background();

$primary: (
        50 : #e0efff,
        100 : #b3d7ff,
        200 : #80bdff,
        300 : #4da2fe,
        400 : #268efe,
        500 : #007afe,
        600 : #0072fe,
        700 : #0067fe,
        800 : #005dfe,
        900 : #004afd,
        A100 : #ffffff,
        A200 : #f1f4ff,
        A400 : #becdff,
        A700 : #a4b9ff,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #ffffff,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);
$warn: (
        50 : #f9e7e7,
        100 : #efc2c2,
        200 : #e49a9a,
        300 : #d97272,
        400 : #d15353,
        500 : #c93535,
        600 : #c33030,
        700 : #bc2828,
        800 : #b52222,
        900 : #a91616,
        A100 : #ffdcdc,
        A200 : #ffa9a9,
        A400 : #ff7676,
        A700 : #ff5d5d,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #ffffff,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);
$accent: (
        50 : #fff8e0,
        100 : #ffeeb3,
        200 : #ffe380,
        300 : #ffd84d,
        400 : #ffcf26,
        500 : #ffc700,
        600 : #ffc100,
        700 : #ffba00,
        800 : #ffb300,
        900 : #ffa600,
        A100 : #ffffff,
        A200 : #fffaf2,
        A400 : #ffe6bf,
        A700 : #ffdca6,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #000000,
                500 : #000000,
                600 : #000000,
                700 : #000000,
                800 : #000000,
                900 : #000000,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

$typography: mat.m2-define-typography-config(
        $font-family: Inter,
        $headline-5: mat.m2-define-typography-level(26px, 1, 700),
        $headline-6: mat.m2-define-typography-level(20px, 1, 400),
        $body-1: mat.m2-define-typography-level(14px, 1, 400),
        $body-2: mat.m2-define-typography-level(14px, 1, 400),
        $caption: mat.m2-define-typography-level(12px, 1, 400),
        $button: mat.m2-define-typography-level(14px, 1, 400),
);

$light: mat.m2-define-light-theme((
        color: (
                primary: mat.m2-define-palette($primary),
                accent: mat.m2-define-palette($accent),
                warn: mat.m2-define-palette($warn),
        ),
        typography: $typography,
        density: 0,
));

$dark: mat.m2-define-dark-theme((
        color: (
                primary: mat.m2-define-palette($accent),
                accent: mat.m2-define-palette($accent),
                warn: mat.m2-define-palette($warn),
        ),
        typography: $typography,
        density: 0,
));

@include mat.all-component-themes($light);
@include mat.typography-hierarchy($typography);

.dark {
        @include mat.all-component-colors($dark);
        @include mat.tabs-color($dark);
}

:root {
        --mat-sidenav-content-background-color: white;
}
